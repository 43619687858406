import { Link } from "gatsby";

export default function CategorySidebar({ categories, base_url }) {
  return (
    <div>
      <h1 className="lg:text-4xl font-semibold text-blue-800 ">
        Filtrar por categoria
      </h1>
      <ul className="py-5 flex flex-row flex-wrap gap-3 text-sm">
        {categories.map((item) => (
          <li
            key={item.url}
            className="bg-blue-800 hover:bg-blue-900 text-center py-2 px-4 mb-1 rounded-2xl shadow-xl font-light text-white transition hover:transform hover:duration-150 hover:-translate-y-1"
          >
            <Link to={"/" + base_url + "/" + item.url}>{item.por_version}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
